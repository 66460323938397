.container {
  background-color: rgba(50, 54, 57, 0.8);
}

.container_2 {
  padding: 20px;
}

.name {
  text-align: left;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.7rem;
  font-family: "Major Mono Display", monospace;
  padding: 15px;
}

.icon {
  color: #ec9688;
}

.response {
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: "Major Mono Display", monospace;
  font-size: 2rem;
  background-color: rgba(50, 54, 57, 0.8);
  border-radius: 25px;
  padding: 20px;
}

.input {
  color: #202124;
  font-family: "Major Mono Display", monospace;
  font-size: 1rem;
}

.submit {
  color: #202124;
  font-size: 1rem;
  font-family: "Major Mono Display", monospace;
}

.gif {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}
