.center {
  justify-content: center;
  display: flex;
  width: auto;
  position: relative;
  height: 80px;
}

.bottom {
  position: absolute;
  bottom: 0;
}

.icons {
  color: #ec9688;
  transition: 0.5s;
}

.icons:hover {
  color: #ffffff;
}

.prolang {
  color: #ec9688;
}
