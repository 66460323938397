.introtext {
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  font-family: "Major Mono Display", monospace;
  background-color: rgba(50, 54, 57, 0.8);
  border-radius: 25px;
  margin-top: 1rem;
}

.height {
  position: relative;
  top: 20px;
}

.parallax {
  z-index: -1;
  position: absolute;
}
