.outer {
  overflow: visible;
}

.BG {
  background-color: #000000;
  width: auto;
  height: auto;
  background-size: cover;
  padding-bottom: 350px;
  margin-bottom: -350px;
  padding-top: 250px;
  margin-top: -250px;
}
