.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.timeline-container::after {
  background-color: #ec9688;
  content: "";
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;
  position: relative;
  width: 500px;
  max-width: 70%;
  text-align: right;
}

.timeline-item-content::after {
  content: " ";
  background-color: #ffffff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 7px;
  height: 7px;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content time {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  font-family: "Major Mono Display", monospace;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  max-width: auto;
  font-family: "Major Mono Display", monospace;
}

.timeline-item-content .circle {
  background-color: #ffffff;
  border: 3px solid #ec9688;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
  }

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timeline-item-content time {
    margin-top: 20px;
  }
}
