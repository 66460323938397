.word {
  color: #ffffff;
  font-size: 1.5rem;
  font-family: "Major Mono Display", monospace;
  text-align: center;
}

.top_padding {
  padding-top: 40px;
}

.height {
  height: auto;
  /*
  overflow: visible;
  position: relative;
  */
}

.box {
  transition: all 1s ease-out;
}

.box:hover,
.box:focus {
  background-color: rgba(50, 54, 57, 0.8);
  border-radius: 25px;
  font-weight: bold;
  font-size: 110%;
  padding: 2%;
}

.parallax {
  position: relative;
  top: 500px;
}

/*position: absolute; 





*/
