.outer {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 750px;
}

.parallax {
  position: relative;
  object-fit: cover;
}
